@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}
/* Header Start */
header {
  @apply md:h-[5.5rem]
}
header nav div button {
  align-self: center;
}
.active-link {
  color: #006AB7;
  border-bottom: 2px solid #006AB7;
}
/* Header End */


/* Hero Section Start */
.hero {
  background-color: #F3F6FD;
}
.hero div:first-child {
  height: calc(100vh - 5.5rem);
  /* height: calc(100vh); */
}
.hero .hero-md {
  /* margin-left: 10rem; */
  position: absolute;
  right: calc(-5%);
  bottom: 0;
  @apply xl:h-[85%] lg:h-[70%] md:h-[55%] hidden md:block;
  /* @apply xl:h-[85%] lg:h-[75%] hidden lg:block; */
}
.hero h1 span {
  color: #006AB7;
  @apply font-bold;
}
.btn-hero {
  @apply relative z-20 p-2 md:px-4 md:py-2 rounded-full hover:scale-x-110 transition-all shadow-md;
}
/* Hero Section End */

/* Services Start */

.services {
  background: url('./assets/pages/home/services/shape-01.png') #00345A;
  background-size: cover;
}
.services .services-title {
  letter-spacing: 0.3em;
  @apply text-white font-normal text-[1.25rem] pt-10 pb-2;
}
.img-service {
  max-height: max-content;
  max-width: max-content;
  @apply m-auto mx-4;
}
.services .info h2 {
  @apply font-bold text-[1.25rem];
}
.services .see-more {
  @apply flex items-center justify-center mt-5;
}
.services .see-more a {
  @apply font-semibold flex gap-4 items-center;
}
.services .see-more a span {
  color: #006AB7;
}
.services .see-more .service-arrow {
  @apply h-full min-w-fit;
  filter: invert(25%) sepia(88%) saturate(1858%) hue-rotate(187deg) brightness(93%) contrast(102%);
}
.services .see-more:hover .service-arrow {
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(246deg) brightness(101%) contrast(107%);
  @apply translate-x-2 transition-all;
}
.services .see-more:hover a span {
  @apply text-black transition-all;
}
/* Services End */

/* Brands Start */
.brands {
  background-color: #F3F6FD;
  @apply text-center;
}
.brands .swiper {
  @apply py-10;
}
/* Brands End */
.brands h2,
.clients h2 {
  @apply text-2xl md:text-4xl font-bold pt-10;
}
/* Clients Start */
.clients {
  background-color: #002C4D;
  @apply text-center text-white;
}
.clients .swiper {
  @apply py-10;
}
/* Clients End */

/* Footer Start */
footer {
  background-color: #002642;
  @apply text-white relative;
}
footer section {
  @apply mx-auto w-11/12 md:w-10/12 lg:w-11/12 grid grid-cols-1 lg:grid-cols-4 py-10 gap-y-4 md:gap-y-0 relative z-20;
}
footer h3 {
  @apply text-2xl mb-4 font-semibold;
}
footer .social-links {
  @apply flex gap-x-2 mt-4;
}
footer .social-links a {
  @apply transition-all;
}
footer .social-links .facebook-link {
  width: 1.625rem;
  height: 1.625rem;
  background-image: url('/src/assets/facebook-white.svg') ;
  background-size: cover;
  /* background-repeat: no-repeat; */
}
footer .social-links .facebook-link:hover {
  /* width: 1.625rem; */
  /* width: 1.625rem;
  height: 1.625rem; */
  background-image: url('/src/assets/facebook-color.svg');
}
footer .social-links .whatsapp-link {
  width: 1.625rem;
  height: 1.625rem;
  background-image: url('/src/assets/whatsapp-white.svg') ;
  background-size: cover;
  /* background-repeat: no-repeat; */
}
footer .social-links .whatsapp-link:hover {
  /* width: 1.625rem; */
  /* width: 1.625rem;
  height: 1.625rem; */
  background-image: url('/src/assets/whatsapp-color.svg');
}
footer .social-links a:hover {
  @apply cursor-pointer;
}
footer .footer-services {
  @apply col-span-2
}
footer .footer-services ul {
  @apply grid grid-cols-2 gap-y-0.5;
}
footer .footer-services ul li {
  @apply hover:font-semibold transition-all;
}
footer .footer-services ul li a {
  @apply flex gap-2 items-center;
}
footer .footer-services ul img {
  @apply h-3;
}
.footer-contact ul {
  @apply space-y-2;
}
.footer-contact ul li {
  @apply flex gap-2;
}
footer .copyrights {
  border-top: 1px solid #D9D9D9;
  @apply mx-auto w-11/12 md:w-10/12 lg:w-11/12 flex justify-center py-5 relative z-20 text-[0.8rem] md:text-[1rem];
}
footer .copyrights .line-vertical {
  width: 1px;
  background-color: #6C6C6C;
  @apply mx-4;
}
/* Footer End */



/* SWIPER CSS START */
.swiper {
  width: 100%;
  height: 100%;
}   
.swiper-slide {
  text-align: center;
  /* font-size: 18px; */
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
.brands-swiper .swiper-slide img {
  display: block;
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.clients-swiper .swiper-slide img {
  display: block;
  width: 350px;
  height: auto;
  /* height: 175px; */
  object-fit: cover;
}
/* Services - Sub-pages  */
.img-content .swiper img {
  align-self: flex-start;
  display: block;
  object-fit: cover;
}
/* SWIPER CSS END */


.main-title {
  @apply text-center text-white py-5;
}
.main-title h3 {
  @apply text-4xl font-semibold tracking-wider;
}
main section {
  @apply mx-auto w-11/12 py-10 md:w-10/12 lg:w-11/12;
  /* @apply mx-auto w-11/12 py-10 md:w-10/12 lg:w-11/12 ; */
}
/* SISTEMAS START */
main section .content {
  @apply space-y-4;
}
main section .content h3 {
  @apply text-center font-bold text-xl md:text-left;
}
main section .img-content .swiper-slide img {
  @apply relative rounded-lg overflow-hidden;
}
details summary {
  cursor: pointer;
  margin-bottom: -10px; /* for more prominent move */
  /* margin-bottom: -2rem; */
  transition: margin 150ms ease-out;
}
details[open] summary {
  /* margin-bottom: 2rem; */
  margin-bottom: 10px;
}
details[open] article {
  margin-top: -10px;
}
/* SISTEMAS END */

/* CONTACT START */
main section .social-links {
  @apply flex gap-x-2 mt-4;
}
main section .social-links a {
  @apply transition-all;
}
main section .social-links .facebook-link {
  width: 2rem;
  height: 2rem;
  /* width: 1.625rem; */
  /* height: 1.625rem; */
  background-image: url('/src/assets/facebook-white.svg') ;
  background-size: cover;
}
main section .social-links .facebook-link:hover {
  background-image: url('/src/assets/facebook-color.svg');
}
main section .social-links .whatsapp-link {
  width: 2rem;
  height: 2rem;
  /* width: 1.625rem; */
  /* height: 1.625rem; */
  background-image: url('/src/assets/whatsapp-white.svg') ;
  background-size: cover;
}
main section .social-links .whatsapp-link:hover {
  background-image: url('/src/assets/whatsapp-color.svg');
}
/* CONTACT START */
